<template>
  <div class="eyebrows">
    <Hero src="obrve-hero.jpg" :heading-text="'Obrve'" :heading-subtitle="'METODA ISCRTAVANJA DLAČICA VAM OMOGUĆAVA POTPUNO PRIRODNI IZGLED OBRVA'" :cta-text="'ZAKAŽITE TERMIN'" background-url="obrve-hero.jpg" background-url-mobile="obrve-mobile.jpg"></Hero>
    <HeadlineCopy src1="100.jpg" src2="boldbrows.png" :heading-text="'100% <br/> prirodni <br/> izgled obrva'" :heading-subtitle="'Obrve služe da istaknu lepotu i oblik prirodnih crta lica. <br> Zajednička karakteristika različitih tehnika- microblading, senčenje, kao i kombinacija ovih metoda, je da su potpuno neinvazivne, <br/>dok istovremeno postižu izuzetne rezultate. <br/>Rezultat koji se postiže je takav da obrve ostavljaju utisak potpune prirodnosti ili intenzivniji u zavisnosti od želje klijenata.'" :cta-text="'Zakažite termin'"></HeadlineCopy>
    <SingleTextComponent :heading-text="'PRIRODAN I INTEZIVAN IZGLED OBRVA '" background-url="sencenje.jpg"></SingleTextComponent>
    <HeadlineCopy src1="naslov-2.jpg" :heading-text="'Intezivan<br/> izgled obrva'" :heading-subtitle="'U zavisnosti od vasih želja, izgled obrva može biti prirodan ili intenzivan. Ukoliko se odlučite za intenzivan oblik, uz različite tehnike postižemo rezultat tačno onakav kakav želite.'"></HeadlineCopy>
    <Footer></Footer> 
  </div>
</template>

<script>
// @ is an alias to /src
import Hero from '@/components/Hero.vue'
import HeadlineCopy from '@/components/HeadlineCopy.vue'
import SingleTextComponent from '@/components/SingleTextComponent.vue'
import Footer from '@/components/Footer.vue'



export default {
  name: 'Eyebrows',
  components: {
    Hero,
    HeadlineCopy,
    SingleTextComponent,
    Footer
  }
}
</script>